<template>
  
<div  class="card  shadow border border-white bg-white h-100 pb-3 overflow-auto">


        <div class="row pb-1 border-bottom border-info m-3">


                <h4 class="font-weight-lighter text-info"> <span class="fa fa-file-alt"></span> Bon De Livraison</h4> 
            
        </div>

          <div class="row m-5">

        <div class="col-md-8 ml-auto mr-auto">

           <form @submit.prevent="handleSubmitBonCMD()">

            <div class="row  mt-2">
            <div class="form-group col">
                <label for="qte">N° Bon /Facture :</label>
                <input type="text" v-model="itemFormBon.nom" id="qte" name="qte" class="form-control"
                 :class="{ 'is-invalid': isSubmitted && $v.itemFormBon.nom.$error }"/>
                 
                <div v-if="isSubmitted && !$v.itemFormBon.nom.required" class="invalid-feedback">Entrez Le N° de la facture /bon Fournisseur!!</div>


            </div>


            <div class="form-group col">
                <label for="date_exp" class="mr-sm-2">Date :</label>
                    <b-form-datepicker
                    class="mr-sm-2"
                    id="datepicker-date_exp"
                    placeholder="date"
                    v-model="itemFormBon.date"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
            </div>


            </div>



            <div class="row mt-2">
                        <div class="form-group col">

                            <label for="fournisseur_id">Fournisseur :</label>
                            <select class="form-control" id="fournisseur_id" v-model="itemFormBon.fournisseur_id"
                            :class="{ 'is-invalid': isSubmitted && $v.itemFormBon.fournisseur_id.$error }">
                            <option v-for="item in fournisseurs" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>

                            </select>

                             <div v-if="isSubmitted && !$v.itemFormBon.fournisseur_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>

                         </div>

                         <div class="form-group col">

                            <label for="depot_id">Dépôt :</label>
                            <select :disabled="!nom_dis" class="form-control" id="depot_id" v-model="itemFormBon.depot_id"
                            :class="{ 'is-invalid': isSubmitted && $v.itemFormBon.depot_id.$error }">
                            <option v-for="item in depots" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>

                            </select>

                             <div v-if="isSubmitted && !$v.itemFormBon.depot_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>

                         </div>
            </div>


            <div class="form-group mt-2">
            <label for="adresse">Note :</label>
            <textarea name="adresse" id="adresse"  rows="2" class="form-control" v-model="itemFormBon.description"></textarea>
            </div>


            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block">Suivant</button>
            </div>

        </form>

        </div>   

</div> 

    
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>

 </div>






  
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";

export default {
 props: ['ResivedId'],
 computed: {
    user_name() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.name;
      }
      else
      {
            return '';
      }
    },
    user_dep() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.dep;
      }
      else
      {
            return 0;
      }
    },

  },
mounted(){

this.fetchfournisseursDepots()

if(this.ResivedId)
{
this.process_modification(this.ResivedId)
}
else
{
this.process_Ajouter()
}

    },
data () {
		return {

            fournisseurs:[],
            depots:[],
            itemFormBon: {
                    id:"",
                    nom:'Livraison',
                    date:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    valider:false,
                    regler:false,
                    total_ttc:0,
                    description:'',
                    fournisseur_id:'',
                    depot_id:'',
                    user:'user'},

            isSubmitted: false,
            isLoading: false,
            isModification:false,


            //boninfos Champs disibl:
            nom_dis:true,
        


		}
    },
    validations: {
            itemFormBon: {
                nom: {
                    required
                },
                date: {
                    required
                },
                valider: {
                    required
                },
                total_ttc: {
                    required
                },
                regler: {
                    required
                },
                depot_id: {
                    required
                },
                fournisseur_id: {
                    required
                },
                user: {
                    required
                }
            }

    },
methods:{

        process_modification(id_resived)
        {
                    this.isLoading=true;
                    this.$http.get('/livrisonfours/'+id_resived)
                    .then((result) => {
                    
                    //affectation des information
                    this.itemFormBon.id=result.data.data.id,
                    this.itemFormBon.nom=result.data.data.nom,
                    this.itemFormBon.date=result.data.data.date,
                    this.itemFormBon.valider=result.data.data.valider,
                     this.itemFormBon.regler=result.data.data.regler,
                    this.itemFormBon.total_ttc=result.data.data.total_ttc,
                    this.itemFormBon.description=result.data.data.description,
                    this.itemFormBon.fournisseur_id=result.data.data.fournisseur_id,
                    this.itemFormBon.depot_id=result.data.data.depot_id,
                    this.itemFormBon.user=result.data.data.user




                    //boninfos Champs disibl:
                        this.nom_dis=false,
                     


                     //Activer la modification
                     this.isModification=true,


                     //desible load
                     this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

        },
        process_Ajouter()
        {

            this.itemFormBon.total_ttc=0

                    //boninfos Champs disibl:
                        this.nom_dis=true,
              

                     //Activer la modification
                     this.isModification=false

        }
        ,

        fetchfournisseursDepots: function () {

                    //chager les fournisseurs:
                    this.isLoading=true;
                    this.$http.get('/fournissMinActif')
                    .then((result) => {
                                this.fournisseurs = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });


                    //chager les depots:
                    this.isLoading=true;
                    this.$http.get('/depotPerson/'+this.user_dep)
                    .then((result) => {
                                this.depots = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        handleSubmitBonCMD(){

                //USER HANDLE
                this.itemFormBon.user= this.user_name

                
                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                    if(this.isModification)
                    {
                
                    this.edit_bon()
                    }
                    else
                    {
                    
                    this.add_bon()
                    }
   

                }


        },
        add_bon()
        {

            this.isLoading=true;

            this.$http.post('/livrisonfours',{

                    nom:this.itemFormBon.nom,
                    date:this.itemFormBon.date,
                    valider:this.itemFormBon.valider,
                    regler:this.itemFormBon.regler,
                    total_ttc:this.itemFormBon.total_ttc,
                    description:this.itemFormBon.description,
                    depot_id:this.itemFormBon.depot_id,
                    fournisseur_id:this.itemFormBon.fournisseur_id,
                    user:this.itemFormBon.user


                })
                .then(response => {
                    //metre a jour id
                    this.itemFormBon.id=response.data.data.id,
                    this.itemFormBon.nom=response.data.data.nom,

                    //boninfos Champs disibl:
                    this.nom_dis=false,
          


                    //Activer la modification
                    this.isModification=true,


                    this.isLoading=false;
                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '


                })
                    //afficher les details:

                   let four = this.fournisseurs.findIndex(item => item.id === response.data.data.fournisseur_id)
                    let dep = this.depots.findIndex(item => item.id === response.data.data.depot_id)

                    this.preparerDetail(
                    response.data.data.id,
                    response.data.data.nom,
                    response.data.data.fournisseur_id,
                    this.fournisseurs[four].nom,
                    response.data.data.depot_id,
                    this.depots[dep].nom
                    )

                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


               this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });


        },
        edit_bon()
        {

            this.isLoading=true;

            this.$http.patch('/livrisonfours/'+this.itemFormBon.id,{

                    nom:this.itemFormBon.nom,
                    date:this.itemFormBon.date,
                    valider:this.itemFormBon.valider,
                    regler:this.itemFormBon.regler,
                    total_ttc:this.itemFormBon.total_ttc,
                    description:this.itemFormBon.description,
                    depot_id:this.itemFormBon.depot_id,
                    fournisseur_id:this.itemFormBon.fournisseur_id,
                    user:this.itemFormBon.user
                })
                .then(response => {

                    this.isLoading=false;
                    console.log(response.data.data);


                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })

                //afficahge detail

                    let four = this.fournisseurs.findIndex(item => item.id === response.data.data.fournisseur_id)
                    let dep = this.depots.findIndex(item => item.id === response.data.data.depot_id)

                    this.preparerDetail(
                    response.data.data.id,
                    response.data.data.nom,
                    response.data.data.fournisseur_id,
                    this.fournisseurs[four].nom,
                    response.data.data.depot_id,
                    this.depots[dep].nom
                    )

                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


               this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });


        },
        preparerDetail(id_bon,nom,id_four,nom_fou,id_dep,nomDep)
        {

                        this.$router.push({ 
                        name: 'bnLivraisonFourDetail', 
                        params: { 
                        idBon: id_bon,
                        nomBon: nom,
                        idFour: id_four,
                        nFour: nom_fou,
                        idDep:id_dep,
                        nDep:nomDep
                        } 
                            });
        }

        



},
components: {
              Loading      
}


}
</script>

<style>

</style>